import React, { useState } from "react";
import ReactModalLogin from "react-modal-login";
import "react-modal-login/dist/react-modal-login.css";
import { FaGraduationCap } from 'react-icons/fa';

import styles from './activities.module.css'


const LectureNotes = (props) => {
  const [state, setState] = useState({
    showModal: false,
    loading: false,
    error: null,
    initialTab: null,
    recoverPasswordSuccess: null,
  });

  const onLogin = async (e, a, c) => {

    const email = document.querySelector("#email").value;
    const password = document.querySelector("#password").value;

    if (!email || !password) {
      setState({
        error: true,
      });
      onLoginFail("form");

    } else {
      onLoginFail("form");
    }
  };

  const openModal = (initialTab) => {

    setState({
      ...state,
      initialTab: initialTab,
      showModal: true,
    });
  };

  const onLoginSuccess = (method, response) => {

    setState({
      ...state,
      showModal: false,
      error: null,
      loading: false,
    });
  };

  const onLoginFail = (method, response) => {
    setState({
      ...state,
      loading: false,
      error: 'Error',
    });
  };

  const startLoading = () => {
    setState({
      ...state,
      loading: true,
    });
  };

  const finishLoading = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const afterTabsChange = () => {

    setState({
      ...state,
      error: null,
      recoverPasswordSuccess: false,
    });
  };

  const closeModal = () => {
    setState({
      ...state,
      showModal: false,
      error: null,
      loading: false,
    });
  };

  const isLoading = state.loading;


  return (
<>
    <a className={styles.teaching} onClick={() => openModal('login')} rel="noopener noreferrer" target="_blank" aria-label="Teaching - Lecture notes" title="Teaching - Lecture notes">
     <FaGraduationCap className={styles.graduate}/> <span className={styles.lecture}>Lecture notes</span>
    </a>
      <ReactModalLogin
        visible={state.showModal}
        onCloseModal={closeModal}
        loading={isLoading}
        initialTab={state.initialTab}
        error={state.error}
        startLoading={startLoading}
        finishLoading={finishLoading}
        loginError={{
          label: "Access denied"
        }}
        registerError={{
          label: "Registrations are closed"
        }}
        form={{
          onLogin: onLogin,
          loginBtn: {
            label: "Sign in",
          },

          loginInputs: [
            {
              containerClass: "RML-form-group",
              label: "Email",
              type: "email",
              inputClass: "RML-form-control",
              id: "email",
              name: "email",
              placeholder: "Email",
            },
            {
              containerClass: "RML-form-group",
              label: "Password",
              type: "password",
              inputClass: "RML-form-control",
              id: "password",
              name: "password",
              placeholder: "Password",
            },
          ],
         
        }}

      />
    </>
  );
};

export default LectureNotes;