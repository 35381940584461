import React from 'react'
import styles from './about.module.css'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <>
    <h1 className={styles.headline}>Stefano Noferi</h1>
    <p className={styles.underline}>
      Tech Entrepreneur, IT Architect, Innovation Manager<br/>
    </p>
  </>
)
