import React from 'react'
import styles from './activities.module.css'
import noze from '../images/noze.jpg';
import ykrobotics from '../images/yk-robotics.png';

import LectureNotes from './LectureNotes';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <div className={styles.activities}>
    <a href="https://www.noze.it/" rel="noopener noreferrer" target="_blank" aria-label="noze - Innovation Agency" title="noze - Innovation Agency">
      <img className={styles.noze} src={noze} alt="noze - Innovation Agency"/>
    </a>

    <br/>

    {/*
    <a href="https://www.yk-robotics.com/" rel="noopener noreferrer" target="_blank" aria-label="YK-Robotics - the robotics You Know" title="YK-Robotics - the robotics You Know">
      <img className={styles.yk} src={ykrobotics} alt="YK-Robotics - the robotics You Know"/>
    </a>
    */}
    
    <br/>

    <LectureNotes/>

  </div>  
)
