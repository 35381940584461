import React from 'react';
import Particles from "react-tsparticles";
import styles from './App.module.css';
import particlesOptions from "./particles.json";
import About from './components/about';
import Links from './components/links';
import Activities from './components/activities';

function App() {
    return (
      <> 
        <Particles className={styles.particles} params={particlesOptions}/>
        <div className={styles.outerContainer}>
          <div className={styles.container}>
            <About />
            <Links />
            <Activities />
          </div>
        </div>
      </>
    );
}

export default App;
