import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Helmet } from 'react-helmet';

import {
  BrowserRouter as Router
} from "react-router-dom";

ReactDOM.render(
  
  <React.StrictMode>
    <Router>
        <Helmet htmlAttributes={{ lang : 'en' }}>
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />

        <title>Stefano Noferi - Tech Entrepreneur, IT Architect, Innovation Manager</title>
        <meta name="title" content="Stefano Noferi - Tech Entrepreneur, IT Architect, Innovation Manager" />
        <meta name="description" content="Stefano Noferi - Tech Entrepreneur, IT Architect, Innovation Manager" />
        <link rel="canonical" href="https://noferi.it" />
  
        <meta name="application-name" content="Stefano Noferi" />
        <meta name="theme-color" content="#48bfcd" />
        <meta name="apple-mobile-web-app-title" content="Stefano Noferi" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="mobile-web-app-capable" content="yes" />
      </Helmet>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
