import React from 'react'
import styles from './links.module.css'
import { FaLinkedin, FaFacebook } from 'react-icons/fa';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <div className={styles.links}>
    <a href="https://www.linkedin.com/in/stefanoferi/" rel="noopener noreferrer" target="_blank" aria-label="LinkedIn profile" title="Linkedin profile">
      <FaLinkedin/>
    </a>

    <a href="https://www.facebook.com/stefano.noferi/" rel="noopener noreferrer" target="_blank" aria-label="Facebook profile" title="Facebook profile">
      <FaFacebook/>
    </a>
  </div>  
)
